import React, { useState } from "react"
import Bg from "./assests/HeroSection/headerBG.svg"
import Logo from "./assests/Logo.png"
import rightSection from './assests/HeroSection/rightSection.png'
import { Link } from "react-scroll";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase";
import { Star } from 'lucide-react'

const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;


const HeaderSection = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleClick = () => {
    navigate("/user");
  };

  return (
    <div className="relative bg-[#0D084D] h-full sm:h-[530px]">
      {/* Background */}
      <img
        src={Bg}
        alt=""
        className="absolute inset-0 w-full h-full object-cover opacity-70"
      />
      {/* Content wrapper */}
      <div className="relative z-10">
        {/* Navbar */}
        <nav className="absolute top-0 left-0 right-0 z-50 border-b border-gray-700/50">
          <div className="max-w-[1400px] mx-auto px-6 lg:px-8">
            <div className="flex items-center justify-between py-4">
              <div className="flex items-center space-x-2">
                <a href= '/' className="cursor-pointer">
                <img src={Logo} alt="Adlytick" className="h-14 w-32 rounded-md"  />
                </a>
                {/* <a
              href="/"
              className="font-montserrat text-white text-sm sm:text-base lg:text-xl ml-2"
            >
              {whiteLabelText}
            </a> */}
            
                          </div>
              
              <div className="hidden md:flex items-center space-x-12">
                {/* <Link to="modelPortfolio" smooth={true} duration={500} className="cursor-pointer text-white hover:text-[#E8B634] transition-colors duration-300">
                  Model Portfolios
                </Link> */}
                <Link to="subscriptionSection" smooth={true} duration={800} className="cursor-pointer text-white hover:text-[#E8B634] transition-colors duration-300">
                  Stock Tips
                </Link>
                <Link to="aboutSection" smooth={true} duration={1000} className="cursor-pointer text-white hover:text-[#E8B634] transition-colors duration-300">
                  About Advisor
                </Link>
                <Link to="faqSection" smooth={true} duration={1000} className="cursor-pointer text-white hover:text-[#E8B634] transition-colors duration-300">
                  FAQ
                </Link>
                <button
                  className="bg-[#E8B634] text-[#0F0F0F] font-semibold px-6 py-2 rounded-md hover:bg-[#c99b2d] transition-colors duration-300 "
                  onClick={handleClick}
                >
                  {userEmail ? "Dashboard" : "How to Invest"}
                </button>
              </div>

              <div className="md:hidden">
                <button
                  onClick={toggleMenu}
                  className="text-white focus:outline-none"
                  aria-label="toggle menu"
                >
                  {!isOpen ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  )}
                </button>
              </div>
            </div>

            {isOpen && (
              <div className="md:hidden py-4 bg-[#091B4B]">
                <div className="flex flex-col space-y-4">
                  <Link to="modelPortfolio" smooth={true} duration={500} className="text-white hover:text-[#E8B634] transition-colors duration-300 px-4" onClick={toggleMenu}>
                    Model Portfolios
                  </Link>
                  <Link to="subscriptionSection" smooth={true} duration={800} className="text-white hover:text-[#E8B634] transition-colors duration-300 px-4" onClick={toggleMenu}>
                    Stock Tips
                  </Link>
                  <Link to="aboutSection" smooth={true} duration={1000} className="text-white hover:text-[#E8B634] transition-colors duration-300 px-4" onClick={toggleMenu}>
                    About Advisor
                  </Link>
                  <Link to="faqSection" smooth={true} duration={1000} className="text-white hover:text-[#E8B634] transition-colors duration-300 px-4" onClick={toggleMenu}>
                    FAQ
                  </Link>
                  <div className="px-4">
                    <button
                      className="w-full bg-[#E8B634] text-[#0F0F0F] px-6 py-2 rounded-md  transition-colors duration-300 font-medium"
                      onClick={() => {
                        handleClick();
                        toggleMenu();
                      }}
                    >
                      {userEmail ? "Dashboard" : "How to Invest"}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </nav>

        {/* Hero Section */}
        <div className="mx-auto max-w-[1400px] px-6 lg:px-8 pt-32 pb-20 md:py-32">
          <div className="grid lg:grid-cols-2 gap-12 items-center">
            <div className="space-y-6">
              <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-white leading-tight">
                Maximize Your Financial Potential with{" "}
                <span className="text-[#E8B634]">ADLYTICK</span>
              </h1>
              
              <p className="text-gray-300 text-base sm:text-lg">
                Transform your wealth with ADLYTICK - where expert human
                insights are amplified with effective and efficient automations.
              </p>

              <div className="flex flex-wrap gap-4">
                <Link to="processSection"  className="w-full sm:w-auto px-8 py-3 border-2 border-white text-white rounded-lg hover:bg-white hover:text-[#0F0F0F] transition-colors font-medium">
                  Explore More
                </Link>
                <button className="w-full sm:w-auto px-8 py-3 bg-[#E8B634] text-[#0F0F0F] rounded-md hover:bg-[#c99b2d] transition-colors font-medium"
             onClick={handleClick}

                >
                  Get Started
                </button>
              </div>


              <div className="flex items-center gap-2 mt-6">
    <div className="flex items-center bg-[#0D084D]/80 rounded-lg px-3 py-1.5">
      <div className="flex items-center">
        <span className="text-white font-bold mr-1">4.6+</span>
        <div className="flex">
          <Star className="w-4 h-4 fill-[#E8B634] text-[#E8B634]" />
          <Star className="w-4 h-4 fill-[#E8B634] text-[#E8B634]" />
          <Star className="w-4 h-4 fill-[#E8B634] text-[#E8B634]" />
          <Star className="w-4 h-4 fill-[#E8B634] text-[#E8B634]" />
          <div className="relative">
            <Star className="w-4 h-4 text-[#E8B634]" />
            <div className="absolute inset-0 overflow-hidden" style={{ width: '50%' }}>
              <Star className="w-4 h-4 fill-[#E8B634] text-[#E8B634]" />
            </div>
          </div>
                  </div>
      </div>
      <span className="text-white text-sm ml-2">(200 ratings)</span>
    </div>
  </div>


            </div>

            <div className="hidden lg:block">
              <div className="relative h-[400px] w-full">
                <img 
                  src={rightSection} 
                  alt="Financial Analytics Illustration"
                  className="w-full h-full object-contain"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeaderSection
