import React from "react";
import Logo from "./assests/Logo.png";


const Footer = () => {
  const url = process.env.REACT_APP_URL;
  const navItems = [
    { name: "Disclaimer", href: `https://${url}.alphaquark.in/disclaimer` },
    {
      name: `T&C by Alphaquark`,
      href: `https://${url}.alphaquark.in/terms-and-conditions-by-alphaquark`,
    },
    {
      name: `T&C by Advisor`,
      href: ` `,
    },
    {
      name: "Privacy Policy",
      // href: `https://${url}.alphaquark.in/privacy-policy`,
      href: `/`,

    },
    // {
    //   name: "Contact Us",
    //   href: ``,
    // },
   
  ];
  
  
  return (
    <footer className="bg-[#0D084D] py-6">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center gap-6">
          {/* Logo and Brand */}
          <div className="flex items-center gap-3">
            <img
              src={Logo}
              alt="Adlytick Analytics Logo"
              className="w-36 h-20 object-contain"
            />
         
          </div>

          {/* Navigation */}
          <nav className="flex flex-wrap justify-center items-center gap-y-2">
            {navItems.map((item, index) => (
              <React.Fragment key={item.name}>
                <a
                  href={item.href}
                  className="text-white hover:text-[#E8B634] transition-colors font-normal font-poppins text-sm px-2"
                >
                  {item.name}
                </a>
                {index < navItems.length - 1 && (
                  <span className={`text-white ${index === 1 ? 'hidden sm:inline' : ''}`}>|</span>
                )}
              </React.Fragment>
            ))}
          </nav>
        </div>

        {/* Divider */}
        <div className="w-full h-px bg-gray-700 my-6"></div>

        {/* Copyright */}
        <div className="text-center text-white">
          <p className="flex flex-col md:flex-row items-center justify-center md:gap-6">
            <span>Copyright©2024 All rights reserved</span>
            <span className="hidden md:inline">|</span>
            <span>
              Made with <span className="text-red-500">❤️</span> by Alphaquark
            </span>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
